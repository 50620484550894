.show-history-page-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #303030;
}

.chat-page-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
/* @media (max-width: 600px) {
  .chat-page-text {
    font-size: 18px;
  }
} */

.show_history_container {
  /* width: 130px;
  height: 34px;
  left: 130px;
  top: 137px; */
  padding: 6px 10px 6px 10px;
  /* width: 120px; */

  background: #ffffff;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.left-chat-box {
  width: 100%;
  /* border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; */
  padding: 12px 14px 10px 16px;
  position: relative;
  /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05); */
}
@media (max-width: 600px), (pointer: coarse) {
  .left-chat-box {
    padding: 10px 14px 8px 16px;
  }
}

/* .left-chat-box.dark {
  background-color: #171717;
}
.left-chat-box:before {
  background-color: rgb(255,255,255);
  content: "";
  position: absolute;
  left: -10px;
  bottom: 0px;
  border-bottom: 14px solid transparent;
  border-left: 10px solid white;
  box-shadow: 0 0 5px rgba(0,0,0,0.05);
  clip-path: inset(-5px 0px -5px 0px);
}
.left-chat-box.dark:before {
  background-color: #171717;
  border-left: 10px solid #171717;
}

.left-chat-box:after {
  background-color: rgba(250,250,250);
  content: "";
  position: absolute;
  left: -40px;
  bottom: 0px;
  border-top: 20px solid #f0f0f0;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-top: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom-right-radius: 14px;
}
.left-chat-box.dark:after {
  background-color: #000;
} */

/* This tries to fix the cases where there is a vertical 
line present between the beak and the main chat bubble */
/* .left-box-overlap-cover {
  width: 2px;
  height: 9px;
  background-color: rgb(255,255,255);
  position: absolute;
  left: -1px;
  bottom: 1px;
  border-top-left-radius: 2px;
  box-shadow: 0px 1px 1px rgba(255,255,255, .5);
}
.left-box-overlap-cover.dark {
  background-color: #171717;
  box-shadow: 0px 1px 1px rgba(23, 23, 23, .5);
} */

.answer-description-text {
  /* color: #B7B7B7; */
  font-size: 12px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 130%;
}
@media (max-width: 600px), (pointer: coarse) {
  .answer-description-text {
    font-size: 12px;
  }
}

.button-chat-box {
  border-radius: 25px;
  padding: 12px 16px 12px 18px;
  position: relative;
  max-width: 440px;
  margin: auto;
}
@media (hover: hover) {
  .button-chat-box:hover {
    cursor: pointer;
  }
}
@media (max-width: 600px), (pointer: coarse) {
  .button-chat-box {
    margin-left: 0px;
    margin-right: 0px;
    padding: 10px 16px 10px 18px;
  }
}

.button-chat-box-ask-share-1 {
  opacity: 0.75;
  border-radius: 100px;
  padding: 24px 32px 24px 36px;
  position: relative;
  max-width: 440px;
  margin: auto;
  font-size: 18px;
}
@media (hover: hover) {
  .button-chat-box-ask-share-1:hover {
    cursor: pointer;
  }
}
@media (max-width: 600px), (pointer: coarse) {
  .button-chat-box-ask-share-1 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 24px 32px 20px 36px;
  }
}

.button-chat-box-ask-share-2 {
  /* background-color: F0F5FF; */
  /* background-color: #f0f5ff; */
  /* color: #111F33; */
  background-color: #f0f5ff;
  color: #111f33;
  opacity: 1;

  opacity: 0.75;
  border-radius: 25px;
  padding: 12px 16px 12px 18px;
  position: relative;
  max-width: 440px;
  margin: auto;
}

.button-chat-box-ask-share-2.dark {
  background-color: #252525;
  color: #ffffff;
  opacity: 0.75;
}
@media (hover: hover) {
  .button-chat-box-ask-share-2:hover {
    cursor: pointer;
  }
}
@media (max-width: 600px), (pointer: coarse) {
  .button-chat-box-ask-share-2 {
    margin-left: 0px;
    margin-right: 0px;
    padding: 10px 16px 10px 18px;
  }
}

@keyframes pulsate {
  0% {
    background-color: #E8E8E8;
    opacity: 1;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  }
  50% {
    background-color: #E8E8E8;
    opacity: 0.8;
    box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.2);
  }
  100% {
    background-color: #E8E8E8;
    opacity: 1;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  }
}

@keyframes pulsate-dark {
  0% {
    background-color: #252525;
    opacity: 1;
    box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.1);
  }
  50% {
    background-color: #252525;
    opacity: 0.7;
    box-shadow: 0px 0px 15px 3px rgba(255, 255, 255, 0.3);
  }
  100% {
    background-color: #252525;
    opacity: 1;
    box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.1);
  }
}

.right-chat-box {
  /* max-width: 80%; */
  width: 100%;
  /* background-color: #2971D7; */
  background: #E8E8E8;
  /* border-color: red; */
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 12px 16px 12px 18px;
  position: relative;

}


.right-chat-box.dark {
  background-color: #262626;
}
@media (max-width: 600px), (pointer: coarse) {
  .right-chat-box {
    padding: 10px 16px 10px 18px;
  }
}

.right-chat-box:before {
  /* background-color: #2971D7; */
  /* background-color: rgba(105, 187, 210, 1.0); */
  background-color: #E8E8E8;
  /* background: linear-gradient(180deg, #2971D7 0%, rgba(26, 165, 195, 0.69) 100%); */
  content: "";
  position: absolute;
  right: -10px;
  bottom: 0px;
  border-bottom: 14px solid transparent;
  border-right: 10px solid white;
}
.right-chat-box.dark:before {
  border-right: 10px solid #262626;
  /* background-color: rgba(105, 187, 210, 1.0); */
  background-color: #252525;
  /* border-right: 10px solid #000; */
}

.right-chat-box:after {
  background-color: #f5f5f5;
  content: "";
  position: absolute;
  right: -40px;
  bottom: 0px;
  border-top: 20px solid #f0f0f0;
  border-left: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-top: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom-left-radius: 14px;
}
.right-chat-box.dark:after {
  background-color: #121212;
}

/* This tries to fix the cases where there is a vertical 
line present between the beak and the main chat bubble */
.right-box-overlap-cover {
  width: 2px;
  height: 9px;
  /* background-color: #2971D7; */
  /* background-color: rgba(105, 187, 210, 1.0); */
  background-color: #E8E8E8;
  /* rgba(26, 165, 195, 0.69); */
  /* background-color: linear-gradient(180deg, #2971D7 0%, rgba(26, 165, 195, 0.69) 100%); */
  /* z-index: 999; */
  position: absolute;
  right: -1px;
  bottom: 1px;
  border-top-right-radius: 2px;
  /* box-shadow: 0px 1px 1px rgba(106, 171, 255, .4); */
  /* box-shadow: 0px 1px 1px rgba(106, 171, 255, .5); */
}
.right-box-overlap-cover.dark {
  background-color: #252525;
}

.question-button-container {
  position: relative;
  max-width: 40em;
  background-color: #ffffff;
  color: #000000;
  margin-top: 10px;
  padding: 10px 12px;
  border-radius: 2rem 2rem 2rem 2rem;
  /* box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, .35),
    0 0.0125rem 0.25rem rgba(0, 0, 0, .25); */
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  /* border-radius: 17px; */
}
@media (max-width: 600px), (pointer: coarse) {
  .question-button-container {
    max-width: 30em;
  }
}

.ask-next-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #000000;
}

.refresh-container {
  position: absolute;
  right: -13px;
  bottom: -78px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.7s;
  z-index: 999;
}

/* response waiting */

.on-demand-waiting {
  width: 100%;
  background-color: rgba(236, 236, 236, 0.5);
  border-radius: 6px;
  padding: 12px 14px 10px 16px;
  position: relative;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
}
/* @keyframes on-demand-waiting-animation {
  0% { opacity: 0.9;}
  50% { opacity: 0.5;}
  100% { opacity: 0.9;}
} */
@keyframes on-demand-waiting-animation {
  0% {
    opacity: 0.95;
  }
  50% {
    opacity: 0.45;
  }
  100% {
    opacity: 0.95;
  }
}

.left-chat-box-waiting {
  width: 100%;
  background-color: #E8E8E8;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 12px 14px 10px 16px;
  position: relative;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
}
@media (max-width: 600px), (pointer: coarse) {
  .left-chat-box-waiting {
    padding: 10px 14px 8px 16px;
  }
}

.left-chat-box-waiting.dark {
  background-color: rgba(66, 66, 66, 0.5);
}

.left-chat-box-waiting:before {
  background-color: #E8E8E8;
  content: "";
  position: absolute;
  left: -10px;
  bottom: 0px;
  border-bottom: 14px solid transparent;
  border-left: 10px solid #E8E8E8;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
  clip-path: inset(-5px 0px -5px 0px);
}

.left-chat-box-waiting.dark:before {
  background-color: rgba(66, 66, 66, 0.5);
  border-left: 10px solid rgba(66, 66, 66, 0.5);
}

.left-chat-box-waiting:after {
  background-color: #f5f5f5;
  content: "";
  position: absolute;
  left: -40px;
  bottom: 0px;
  border-top: 20px solid #f0f0f0;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-top: 20px solid transparent;
  border-left: 20px solid transparent;
  border-bottom-right-radius: 14px;
}
.left-chat-box-waiting.dark:after {
  background-color: #121212;
}


.background {
  background-color: #F5F5F7;
  width: 100vw;
  height: 100vh;
  text-align: center;
  overflow-y: auto;
}

.main-container {
  background-color: white;
  padding: 22px 40px 12px 40px;
  /* overflow-y: auto; */
}
@media (max-width: 600px), (pointer: coarse) {
  .main-container {
    padding: 10px 24px 1px 24px;
  }
}

.logo-img {
  max-height: 100%;
  max-width: 40px;
}
@media (max-width: 600px), (pointer: coarse) {
  .logo-img {
    max-width: 30px;
  }
}

.back-button {
  position: absolute;
  top: 40px;
  left: 30px;
  cursor: pointer;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 36px;
  gap: 8px;
  margin-bottom: 18px;
  margin-top: 30px;
}
@media (max-width: 600px), (pointer: coarse) {
  .header {
    font-size: 24px;
    margin-top: 10px;
  }
}

.header-paywall-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 28px;
  gap: 8px;
  margin-bottom: 18px;
  margin-top: 24px;
}

.description {
  margin: 20px auto 2px auto;
  font-weight: 400;
  /* font-size: 28px; */
  font-size: 20px;
  line-height: 133%;
}
@media (max-width: 600px), (pointer: coarse) {
  .description {
    margin: 16px auto;
    font-size: 14px;
  }
}

.pro-line {
  width: 100%;
  max-width: 380px;
}
@media (max-width: 600px), (pointer: coarse) {
  .pro-line {
    max-width: 200px;
  }
}


.images {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  /* width: 700px; */
  width: 500px;
  /* height: 40vh; */
  max-width: 50vw;
  /* max-height: 400px; */
  margin: auto;
  margin-bottom: 0px;
}
@media (max-width: 600px), (pointer: coarse) {
  .images {
    height: unset;
    max-width: 100%;
    /* max-width: unset; */
    margin: 24px auto 0px auto;
  }
}

.money-back {
  width: 300px;
}
@media (max-width: 600px), (pointer: coarse) {
  .money-back {
    width: 200px;
  }
}

.selection-area {
  display: flex;
  gap: 20px;
  width: 100%;
  max-width: 500px;
  margin: auto;
}
@media (max-width: 600px), (pointer: coarse) {
  .selection-area {
    gap: 10px;
    width: unset;
    /* background-color: red; */
    margin: auto 12px;
    /* margin-top: 0px; */
    /* margin: auto 10px; */
  }
}

.button-container {
  width: 500px;
  margin: 16px auto;
  max-width: 86%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 600px), (pointer: coarse) {
  .button-container {
    margin: 10px auto 0px auto;
  }
}

.footer {
  width: 500px;
  margin: 10px auto 150px auto;
  max-width: 86%;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  color: #ABABAB;
}
@media (max-width: 600px), (pointer: coarse) {
  .footer {
    margin: 10px auto 150px auto;
    font-size: 10px;
    line-height: 120%;
  }
}

.link {
  cursor: pointer;
  color: #2971D7;
  font-size: 12px;
}

.selection-container {
  /* height: 140px; */
  height: 132px;
  margin-top: 18px;
  position: relative;
  background-color: white;
  padding: 18px;
  text-align: left;
  width: 50%;
  border-radius: 8px;
  box-sizing: border-box;
}
@media (max-width: 600px), (pointer: coarse) {
  .selection-container {
    margin-top: 6px;
    /* height: 130px; */
    height: 110px;
    padding: 12px;
  }
}

.selection-container:hover {
  cursor: pointer;
  /* box-shadow: 0 0 5px 2px rgba(60, 121, 213, 0.5); */
  box-shadow: 0 0 5px 2px rgba(50,50,50, 0.5);
}

.selected {
  /* border: 3px solid #3C79D5; */
  border: 3px solid black;
  /* box-shadow: 0 0 5px 2px rgba(60, 121, 213, 0.5); */
}

.check-mark {
  position: absolute;
  top: 6px;
  right: 7px;
}

.option-duration, .option-price, .option-detail {
  color: black;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 14px;
}
@media (max-width: 600px), (pointer: coarse) {
  .option-duration, .option-price, .option-detail {
    font-size: 14px;
    margin-bottom: 4px;
  }
}

.option-price {
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 600px), (pointer: coarse) {
  .option-price {
    font-size: 14px;
    margin-bottom: 4px;
  }
}

.option-detail {
  color: #595C63;
  font-size: 12px;
}
@media (max-width: 600px), (pointer: coarse) {
  .option-detail {
    font-size: 10px;
  }
}

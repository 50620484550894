.chat_message_container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.outgoing_container {
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.bubble_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.more-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  color: #687487;
  text-align: center;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.img {
  max-height: 100%;
  max-width: 100%;
}

.first-user-text {
  /* color: #FFF; */
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

/* h2 {
  text-align: left;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
} */

.loading-animation {
  font-size: 30px;
  color: rgba(0, 0, 0, 0.3);
  display: inline-block;
  animation: dotty steps(1, end) 2s infinite;
  content: "";
}
.loading-animation.dark {
  color: #ffffff;
}

/* @keyframes move-text {
    0% { bottom: -0.2em; opacity: 1; }
    48% { bottom: 0.2em; }
    96% { bottom: -0.2em; opacity: 1; }
    100% { bottom: -0.2em; opacity: 1; }
} */

/* Styles for the Home */

/* Styles for the chat box */

.bottom-button-placement {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  top: 500px;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 10px;
  bottom: 140px;
}
@media (max-width: 600px), (pointer: coarse) {
  .bottom-button-placement {
    bottom: 70px;
  }
}

/* Placeholder styles */
.input-container {
  position: fixed;
  height: 140px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  /* background-color: #FAFAFA; */
  width: 100%;
}
@media (max-width: 600px), (pointer: coarse) {
  .input-container {
    height: 55px;
  }
}

.absolute-textarea-container {
  padding: 11px 42px 2px 17px;
  height: 30px;
  width: 100%;
  max-width: 80%;
  position: relative;
  /* border: 2px solid blue; */
}

.placeholder-input {
  padding: 12px 28px 2px 22px;
  height: 28px;
  border: none;
  color: #121212;
  border-radius: 35px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  max-height: 68px;
  resize: none;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #E8E8E8;
}
.placeholder-input::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.placeholder-input:focus {
  outline: none;
}

.placeholder-input.dark {
  /* background: rgba(0, 0, 0, 0.25); */
  /* border:  0.5px solid rgba(0, 0, 0, 0.25); */
  /* border: none; */
  color: #f5f5f5;
  opacity: 1;

  background-color: #252525;
}
.placeholder-input.dark::placeholder {
  /* opacity: 1; Firefox */
  /* color: 'rgba(0, 0, 0, 0.9)'; */
  /* color: white; */
  /* color: #888888; */
  color: white;
  opacity: 0.65;
}

.inner-input-container {
  width: 700px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

@media (max-width: 600px), (pointer: coarse) {
  .inner-input-container {
    align-items: center;
    margin-top: 0px;
  }
}

.placeholder-input::-webkit-input-placeholder {
  /* font-weight: bold; */
  opacity: 0.65;
  font-size: 12;
  color: black;
}

/* ::placeholder { */
/* opacity: 1; Firefox */
/* color: 'rgba(0, 0, 0, 0.9)'; */
/* color: black; */
/* opacity: 0.6; */
/* } */

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: "rgba(0, 0, 0, 1.0)";
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: "rgba(0, 0, 0, 1.0)";
}

.question-input-style {
  width: 700px;
  max-width: 96%;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 35px;
  height: 37px;
}

.question-press-container {
  width: 28px;
  height: 28px;
  border-radius: 22px;
  margin-top: 6px;
}
@media (max-width: 600px) {
  .question-press-container {
    margin-top: 5px;
  }
}

.prechat-dot-input-container {
  display: flex;
  align-items: center;

  height: 100%;
  width: 100%;
}

/* ChatBox Styles */
.chatbox-div-container1 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  min-height: 180px;
  background-color: #fafafa;
}

.chatbox-div-container3 {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  background-color: #fafafa;
  bottom: 140px;
}
@media (max-width: 600px), (pointer: coarse) {
  .chatbox-div-container3 {
    bottom: 55px;
  }
}

.chatbox-scroll-container {
  flex: 1;
  overflow-y: auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 10px 20px 8px 20px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  display: flex;
  justify-content: center;
}
@media (max-width: 600px) {
  .chatbox-scroll-container {
    width: 430px;
  }
}

.chatbox-scroll-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.inner-chatbox-scroll-container {
  margin-top: 70px;
  margin-bottom: 200px;
  position: relative;
  width: 800px;
}
/* Narrow will have this width */
@media (max-width: 600px) {
  .inner-chatbox-scroll-container {
    width: 370px;
  }
}
/*  Narrow or tablet/touch will have this */
@media (max-width: 600px), (pointer: coarse) {
  .inner-chatbox-scroll-container {
    margin-bottom: 94px;
    margin-top: 60px;
  }
}

.scroll-down-container {
  z-index: 999;
  position: fixed;
  width: 100%;
  left: 0px;
  bottom: 240px;
  height: 30px;
}
@media (max-width: 600px), (pointer: coarse) {
  .scroll-down-container {
    bottom: 94px;
  }
}

.content {
  height: 500px;
}

.chatbox-time {
  display: flex;
  justify-content: center;
  color: #a1a1a1;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 0px;
}

.share-button {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 0.5px solid #ececec;
  border-radius: 30px;
  padding: 4px 10px 4px 6px;
}

.chat-message {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
}
@media (max-width: 600px), (pointer: coarse) {
  .chat-message {
    font-size: 15px;
  }
}

@media (max-width: 600px), (pointer: coarse) {
  .chat-message.button {
    font-weight: 500;
    font-size: 15px;
  }
}

.advanced-question {
  text-align: center;
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
}
@media (max-width: 600px), (pointer: coarse) {
  .advanced-question {
    font-size: 11px;
  }
}

.chat-message.dark {
  color: #f5f5f5;
}

.chat-message-button-text {
  font-size: 15px;
  text-align: left;
  color: black;
}
@media (max-width: 600px), (pointer: coarse) {
  .chat-message-button-text {
    font-size: 14px;
  }
}

/* NavBar Styles */

.navbar-container {
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 60px;
  mix-blend-mode: darken;
  /* border-bottom: .5px solid rgba(0,0,0,.25); */
}
@media (max-width: 600px), (pointer: coarse) {
  .navbar-container {
    height: 60px;
  }
}

.navbar-account-container {
  margin-top: 10px;
  width: 120px;
  height: 100%;
  border-radius: 8px;
  display: none;
}

.navbar-text-styles {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0);
  cursor: pointer;
  display: none;
}
.navbar-text-styles.dark {
  color: #b7b7b7;
  font-size: 12px;
}

.navbar-hamburger-container {
  width: 50px;
  height: 50px;
  margin-top: 20px;
  margin-right: 32px;
  display: none;
}
.navbar-hamburger-container {
  display: inline;
}

.logged-in {
  margin-top: -6px;
}


.logged-out {
  margin-top: -9px;
}
@media (max-width: 600px), (pointer: coarse) {
  .logged-out {
    margin-top: -9px;
  }
}

.button-share-navbar {
  text-transform: none;
  border-radius: 18px !important;
  padding: 4px 0px 4px 0px !important;
  /* border: 1px solid #2971D7 28%; */
  /* width: 20px; 
  max-width: 20px; */
  min-width: unset;
  min-height: unset;
  /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05); */
  /* margin-top: 4px !important; */
}
@media (max-width: 600px), (pointer: coarse) {
  .button-share-navbar {
    margin-top: -8px !important;
    /* background-color: red; */
  }
}

/*  FOR THE LOGO: */

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: "relative";
  margin-top: 10px;
  height: 22px;
}
@media (max-width: 600px), (pointer: coarse) {
  .logo-container {
    height: 20px;

  }
}

.logo-logo {
  height: 100%;
  /* margin-right: 4px; */
}
/* @media  (max-width: 600px), (pointer: coarse){
  .logo-logo {
    margin-right: 3px;
  }
} */

/* .logo-advisor-text {
  margin-left: 4px;
  font-size: 20px;
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: -1px;
}
@media  (max-width: 600px), (pointer: coarse){
  .logo-advisor-text {
    margin-bottom: -4px;
    margin-left: 3px;
    font-size: 15px;
  }
} */

.logo-footer-container {
  height: 26px;
}
@media (max-width: 600px), (pointer: coarse) {
  .logo-footer-container {
    height: 20px;
  }
}

/* For the sign-up sign-in */

/* Default styles */
.container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  height: 500px;
}

/* Styles when keyboard is open */
.keyboard-open .container {
  max-width: none;
  padding: 10px;
  height: 200px;
}

#toolbar-wrap {
  position: sticky;
  top: 0px;
  height: 60px;
  width: 100%;
  z-index: 100;
}

#toolbar {
  position: absolute;
  left: 0px;
  right: 0px;
  padding: 15px;
  background-color: #f1f5f9;
  z-index: 100;
}

#toolbar.down {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

/*  For the ConnectionIncite */

.incite_text {
  font-family: "Montserrat";
  font-style: normal;
  line-height: 17px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: #706f6f;
}

/* Look up rotating 3d for the INCITE GLOBE: https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/rotate3d */
@keyframes pulse {
  0% {
    transform: scale(0.65);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.65);
  }
}

.loading::after {
  display: inline-block;
  animation: dotty steps(1, end) 2s infinite;
  content: "";
}

@keyframes dotty {
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
}

/*  feedback page styles */

.feedback-input {
  padding: 14px 17px 2px 17px;
  background: #e8e8e8;
  border: 0.5px solid #e8e8e8;
  border-radius: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #000000;
  resize: none;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
.feedback-input::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.feedback-input::placeholder {
  /* opacity: 1; Firefox */
  color: #000000;
}

.feedback-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
}

.feedback-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000;
}

.feedback-input-dark {
  padding: 14px 17px 2px 17px;
  background: #2d2d2d;
  border: 0.5px solid #2d2d2d;
  border-radius: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: #b7b7b7;
  resize: none;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
.feedback-input-dark::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.feedback-input-dark::placeholder {
  /* opacity: 1; Firefox */
  color: #b7b7b7;
}

.feedback-input-dark:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b7b7b7;
}

.feedback-input-dark::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b7b7b7;
}

.feedback-text {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
@media (max-width: 600px), (pointer: coarse) {
  .feedback-text {
    font-size: 13px;
    line-height: 16px;
  }
}

.account-page-text {
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}
@media (max-width: 600px), (pointer: coarse) {
  .account-page {
    font-size: 14px;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.arrow-animate-in {
  animation: fadeInUp 0.2s ease-out forwards;
}

.arrow-animate-out {
  animation: fadeOutDown 0.2s ease-out forwards;
}





/* For the WelcomePrechat */
.welcome-container {
  padding-top: 72px;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5); */
}
@media (max-width: 600px), (pointer: coarse) {
  .welcome-container {
    /* padding-top: 60px; */
    padding-top: 40px;
  }
}

.base-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
}
@media (max-width: 600px), (pointer: coarse) {
  .base-text {
    padding-top: 2px;
  }
}
.base-text.dark {
  color: #F6F6F6;
}

.watchlist-text {
  font-size: 14px;
}
@media (max-width: 600px), (pointer: coarse) {
  .watchlist-text {
    font-size: 12px;
  }
}

.dot-container {
  width: 36px;
  height: 36px;
  margin-right: 20px;
  margin-left: 20px;

  /* margin-top: 4px; */
}
@media (max-width: 600px), (pointer: coarse) {
  .dot-container {
    width: 30px;
    height: 30px;
    margin-right: 14px;
    margin-left: 14px;
    /* margin-top: 4px; */
  }
}

.predict-text {
  margin-left: 22px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
/* @media (max-width: 600px) {
  .base-text {
    font-size: 18px;
  }
} */

.predict-text-small {
  font-size: 13px;
  line-height: 15px;
  margin-left: 24px;
  margin-top: 10px;
}
@media (max-width: 600px), (pointer: coarse) {
  .predict-text-small {
    font-size: 11px;
    line-height: 13px;
    margin-top: 0px;
    margin-left: 24px;
  }
}

.welcome_prechat_header {
  background-color: white;
  width: 100%;
  padding: 22px 10px 22px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05); */
}


.welcome_prechat_welcome_block {
  /* background-color: white;
  width: 500px;
  max-width: 90%;
  padding: 22px 10px 22px 10px;
  filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.30));
  border-radius: 30px;
  margin-top: 22px;
  display: flex;
  align-items: center;
  justify-content: center; */
  /* background-color: rgb(253,253,253); */
  width: 100%;
  margin-top: 28px;
  padding: 22px 10px 22px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 600px), (pointer: coarse) {
  .welcome_prechat_welcome_block {
    margin-top: 20px;
    padding: 10px 2px 10px 2px;
  }
}



.sign-in-up-block {
  padding: 14px 6px 14px 6px;
}
@media (max-width: 600px), (pointer: coarse) {
  .sign-in-up-block {
    padding: 10px 2px 10px 2px;
  }
}

.register-message {
  font-size: 16px;
  line-height: 25px;
  /* font-weight: bold; */
  text-align: center;
}
@media (max-width: 600px), (pointer: coarse) {
  .register-message {
    font-size: 14px;
    line-height: 20px;
  }
}


.icons-styles {
  margin-top: 30px;
  /* margin-left: 10px;
  margin-right: 10px; */
  display: flex;
  justify-content: center;
}
@media (max-width: 600px), (pointer: coarse) {
  .icons-styles {
    margin-top: 22px; 
    /* margin-left: 14px;
    margin-right: 14px; */
  }
}

.icons-descriptions {
  margin-top: 20px;
  /* margin-left: 10px;
  margin-right: 10px; */
  display: flex;
  justify-content: center;
}
@media (max-width: 600px), (pointer: coarse) {
  .icons-descriptions {
    margin-top: 6px;
    /* margin-left: 14px;
    margin-right: 14px; */
    margin-bottom: 12px;
  }
}

.icons-descriptions-top {
  margin-bottom: 25px;
}
@media (max-width: 600px), (pointer: coarse) {
  .icons-descriptions-top {
    margin-bottom: 16px;
  }
}

.descriptions-text {
  font-size: 15px; 
  line-height: 22px;
  font-weight: 400;
}
@media (max-width: 600px), (pointer: coarse) {
  .descriptions-text {
    font-size: 11px; 
    line-height: 13px;
  }
}

.main-header-text {
  /* margin-left: 22px; */
  font-size: 18px;
  line-height: 26px;
  font-weight: 900;
}
@media (max-width: 600px), (pointer: coarse) {
  .main-header-text {
    /* margin-left: 22px; */
    font-size: 15px;
    line-height: 24px;
    font-weight: 900;
  }
}

.answers-chatgpt-cannot {
  font-size: 18px; 
  line-height: 26px;
  font-weight: 400;
  color: #262626;
  text-align: center;
}
@media (max-width: 600px), (pointer: coarse) {
  .answers-chatgpt-cannot {
    font-size: 14px; 
    line-height: 21px;
  }
}

.categories-text {
  font-size: 18px; 
  line-height: 22px;
  font-weight: 400;
  color: #262626;
}
@media (max-width: 600px), (pointer: coarse) {
  .categories-text {
    font-size: 14px; 
    line-height: 16px;
    color: #262626;
  }
}

.coming-soon-text {
  /* color: #C2C2C2; */
  font-size: 15px; 
  line-height: 22px;
  font-weight: 400;
}
@media (max-width: 600px), (pointer: coarse) {
  .coming-soon-text {
    font-size: 13px; 
    line-height: 16px;
  }
}

.about_prechat_container {
  /* width: 127px; */
  /* height: 34px; */
  /* box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05); */
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
}

.about_prechat {
  /* width: 200px;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 14px 14px 10px 14px; */
  background: #ECECEC;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  padding: 14px 14px 14px 14px;
}

.i_understand {
  
  /* height: 38px; */
  /* left: 119px; */
  /* top: 555px; */
  background: #6AABFF;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  padding: 14px 24px 14px 24px;
}

.conditions_container {
  margin: 10px 40px 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 600px), (pointer: coarse) {
  .conditions_container {
    margin: 10px 20px 0px 20px;
  }
}

.conditions_text {
  font-size: 14px;
  line-height: 19px;
}
@media (max-width: 600px), (pointer: coarse) {
  .conditions_text {
    font-size: 12px;
    line-height: 17px;
  }
}

.i_understand_container {
  margin-top: 66px;
}
@media (max-width: 600px), (pointer: coarse) {
  .i_understand_container {
    margin-top: 60px;
    margin-bottom: 10px;
  }
}

.i_understand_button {
  width: 210px; 
  height: 50px;
}
/* @media (max-width: 600px), (pointer: coarse) {
  .i_understand_button {
    width: 190px; 
    height: 42px;
  }
} */

.i_understand_text {
  font-size: 17px;
}
@media (max-width: 600px), (pointer: coarse) {
  .i_understand_text {
    font-size: 14px;
    margin-bottom: 4px;
  }
}

.privacy-terms-container {
  top: 90px;
}
@media (max-width: 600px), (pointer: coarse) {
  .privacy-terms-container {
    top: 80px;
  }
}

.incite_container {
  margin-top: 40px;
}
@media (max-width: 600px), (pointer: coarse) {
  .incite_container {
    margin-top: 18px;
  }
}

.about_prechat_button {
  width: 190px;
  height: 50px;
}
@media (max-width: 600px), (pointer: coarse) {
  .about_prechat_button {
    width: 170px;
    height: 40px;
  }
}
.about_prechat_text {
  font-size: 17px;
  color: #000000;
}
@media (max-width: 600px), (pointer: coarse) {
  .about_prechat_text {
    font-size: 14px;
    margin-top: 0px;
    margin-bottom: 1px;
    margin-right: 2px;
  }
}

.bottom_version_text {
  /* margin-top: 20px; */
  font-size: 10px;
  line-height: 16px;
  color: #C2C2C2;
  text-align: center;
  width: 700px;
  max-width: 94%;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 25px;
}
@media (max-width: 600px), (pointer: coarse) {
  .bottom_version_text {
    font-size: 8px;
    line-height: 12px;
    margin-top: 0px;
    margin-bottom: 4px;
  }
}

/* .check-container {
  margin-top
} */
@media (max-width: 600px), (pointer: coarse) {
  .check-container {
    margin-top: 4px
  }
}


.privacy-header-styles {
  /* margin-right: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* @media (max-width: 600px) {
  .privacy-header-styles {
    margin-right: 34px;
  }
} */

.terms-header-styles {
  /* margin-right: 42px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* @media (max-width: 600px) {
  .terms-header-styles {
    margin-right: 30px;
  }
} */

/*  About PRECHAT */
/* .about-header-styles {
  margin-right: 72px;
}
@media (max-width: 600px) {
  .about-header-styles {
    margin-right: 60px;
  }
} */

.about-about_prechat {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;

  color: #000000;
}
@media (max-width: 600px), (pointer: coarse) {
  .about-about_prechat {
    font-size: 13px;
    line-height: 15px;
  }
}



.about-back-container {
  position: fixed;
  left: 16px;
  top: 18px;
  /* width: 100px; */
  /* height: 100px; */
  /* background-color: red; */
  z-index: 9999;
  /* max-width: 90%; */
  /* padding-top: 16px; */
}
/* @media (max-width: 600px) {
  .about-back-container {
    padding-top: 16px;
  }
} */

/*  */
.sign-in-icons {
  width: 20px;
  height: 20px;
  margin-left: 12px;
  margin-right: 10px;
  /* margin-top: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}
/* @media (max-width: 600px), (pointer: coarse) {
  .sign-in-icons {
    width: 26px;
    height: 26px;
    margin-left: 18px;
    margin-right: 18px;
  }
} */

.sign-text {
  /* margin-left: 4px; */
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}
@media (max-width: 600px), (pointer: coarse) {
  .sign-text {
    /* margin-left: 16px; */
    font-size: 16px;
    line-height: 20px;
  }
}

.sign-in-up-text {
  font-size: 22px;
}
@media (max-width: 600px), (pointer: coarse) {
  .sign-in-up-text {
    font-size: 18px;
  }
}


.prechat-coverage {
  /* margin-bottom: 70px; */
  padding-bottom: 32px;
  max-width: 700px;
}
@media (max-width: 600px), (pointer: coarse) {
  .prechat-coverage {
    /* margin-bottom: 28px; */
    padding-bottom: 0px;
  }
}
